var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FieldsBlock',{attrs:{"header":_vm.$t('blocksHeaders.assignments'),"showContent":_vm.showContent,"loading":_vm.loading},on:{"update:showContent":function($event){_vm.showContent=$event},"update:show-content":function($event){_vm.showContent=$event}},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.assignmentGroup.id && !_vm.isEachAssignmentStarted)?_c('Button',{staticClass:"p-button-rounded p-button-secondary p-button-text",staticStyle:{"width":"24px","height":"24px"},attrs:{"title":_vm.isEachAssignmentCanStart ? _vm.$t('button.startAssignmentGroup') : _vm.$t('button.unableToStartAssignmentGroup'),"icon":"mdi mdi-20px mdi-play","data-cy":"start-assignment-group","disabled":!_vm.isEachAssignmentCanStart ||
        !_vm.canI('StartAssignmentGroup') ||
        _vm.loading ||
        _vm.networkError},on:{"click":_vm.startAssignmentGroup}}):_vm._e(),(_vm.assignmentGroup.id)?_c('Button',{staticClass:"p-button-rounded p-button-secondary p-button-text p-mr-1",staticStyle:{"width":"24px","height":"24px"},attrs:{"title":_vm.$t('button.pauseAssignmentGroup'),"icon":"mdi mdi-20px mdi-pause","data-cy":"pause-assignment-group","disabled":!_vm.isSomeAssignmentStarted ||
        !_vm.canI('StopAssignmentGroup') ||
        _vm.loading ||
        _vm.networkError},on:{"click":_vm.stopAssignmentGroup}}):_vm._e(),_c('Dropdown',{staticClass:"button-dropdown",attrs:{"data-cy":"assignment-dropdown","disabled":_vm.loading ||
        _vm.assignmentsTypesOptions.length === 0 ||
        !_vm.canI('CreateAssignment') ||
        _vm.networkError,"options":_vm.assignmentsTypesOptions,"optionLabel":"title","filter":"","appendTo":"body"},on:{"change":_vm.sendToCreateAssignmnent},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('div',{staticClass:"p-d-flex p-ai-center"},[_c('Button',{staticClass:"p-button-outlined p-button-secondary p-button-sm",attrs:{"title":_vm.$t('button.addAssignment'),"data-cy":"add-assignment","icon":"mdi mdi-18px mdi-plus"}})],1)]},proxy:true},{key:"option",fn:function(slotProps){return [_c('div',{staticClass:"p-d-flex p-ai-center",staticStyle:{"max-width":"300px"},attrs:{"data-cy":"assignment-item","title":slotProps.option.title}},[_c('i',{staticClass:"mdi mdi-20px p-mr-2",class:slotProps.option.icon
                ? slotProps.option.icon.iconClass || 'mdi-alarm'
                : 'mdi-alarm',attrs:{"data-cy":"assignment-icon"}}),_c('span',{staticClass:"field-label",attrs:{"data-cy":"assignment-label"},domProps:{"textContent":_vm._s(slotProps.option.title)}})])]}}])})]},proxy:true}])},[_c('div',_vm._l((_vm.assignments),function(assignment,index){return _c('div',{key:assignment.id || assignment.title + index,staticClass:"assignment-row p-d-flex p-ai-center p-mb-3",class:{
        'assignment-row__error': _vm.assignmnentIdsWithErrors.includes(
          assignment.id,
        ),
        'assignment-row__active': _vm.currentAssignment === assignment,
      },attrs:{"data-cy":"assignment-title"}},[_c('i',{staticClass:"mdi mdi-20px p-mr-2",class:_vm.assignmentTypes[assignment.assignmentTypeId] &&
          _vm.assignmentTypes[assignment.assignmentTypeId].icon
            ? _vm.assignmentTypes[assignment.assignmentTypeId].icon.iconClass ||
              'mdi-alarm'
            : 'mdi-alarm',on:{"click":() =>
            !_vm.loading &&
            _vm.setCurrentAssignment(
              assignment === _vm.currentAssignment ? null : assignment,
            )}}),(assignment !== _vm.currentRenaming)?_c('span',{domProps:{"textContent":_vm._s(assignment.title)},on:{"dblclick":() => !_vm.loading && _vm.setRenaming(assignment, index),"click":() => !_vm.loading && _vm.setCurrentAssignment(assignment)}}):_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(assignment === _vm.currentRenaming),expression:"assignment === currentRenaming"},{name:"model",rawName:"v-model",value:(_vm.currentRenamingString),expression:"currentRenamingString"}],ref:assignment.id || assignment.assignmentTypeId + index,refInFor:true,staticClass:"p-inputtext p-component p-filled",staticStyle:{"width":"100%"},attrs:{"type":"text"},domProps:{"value":(_vm.currentRenamingString)},on:{"blur":_vm.rename,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.currentRenaming = null},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.rename.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.currentRenamingString=$event.target.value}}}),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('StatusButtons',{attrs:{"assignment":assignment}}),_c('Button',{staticClass:"delete-button p-button-rounded p-button-secondary p-button-text",attrs:{"title":_vm.$t('button.delete'),"disabled":!['Rejected', 'Stopped', 'NotStarted', undefined].includes(
            assignment.status,
          ) || _vm.loading,"icon":"mdi mdi-20px mdi-delete","data-cy":"delete-assignment"},on:{"click":() => {
            _vm.assignmentForDelete = assignment
            _vm.displayDeleteDialog = true
          }}})],1)}),0),(!_vm.assignmentGroup.id && !_vm.loading)?_c('div',{staticClass:"p-d-flex p-flex-column p-ai-center"},[_c('img',{staticStyle:{"transform":"scale(2)"},attrs:{"src":require("@/assets/empty.svg"),"height":"120","width":"120"}}),_c('span',{domProps:{"textContent":_vm._s(`${_vm.$t('emptyAssignments')}`)}}),_c('br')]):_vm._e(),_c('div',{staticStyle:{"position":"absolute"}},[_c('YesNoDialog',{attrs:{"show":_vm.displayDeleteDialog,"message":_vm.$t('message.deleteAssignment'),"header":_vm.$t('message.deleteTitle')},on:{"update:show":function($event){_vm.displayDeleteDialog=$event},"onYes":_vm.deleteLocalAssignment}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }